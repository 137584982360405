import React from "react";
import useGetImage from "./useGetImage";
import Img from "gatsby-image";
import s from "./Content.module.scss";
const Content = () => {
    const { firstImage } = useGetImage();
    return (<section className={s.box}>
      <div className={s.item}>
        <Img className={s.img} fluid={firstImage.childImageSharp.fluid}/>
        <div className={s.textBox}>
          <h2 className={s.title}>Hop Varieties</h2>
          <p className={s.text}>
            Virgil Gamache Farms, Inc. grows a large selection of high-quality
            aroma hop varieties for craft brewers. Check the following chart for
            interesting facts about the growing region, alpha and beta acid, oil
            range and aroma profiles of each variety. Together, these varieties
            offer a broad spectrum of aromas and flavors. In addition, careful
            cultivation and precisely timed harvesting brings the qualities of
            these special hop varieties to their highest potential. So set your
            brewing creativity loose with these premium hop varieties! Virgil
            Gamache Farms, Inc. and our partner farmers are proud to grow these
            aroma hops in the Yakima Valley of Washington and prime locations in
            Idaho, Oregon and five states in Germany. All in all, they add
            excitement through their genetic strain, growing region and advanced
            cultivation practices
          </p>
        </div>
      </div>
    </section>);
};
export default Content;
