import React, { useState } from "react";
import Popup from "reactjs-popup";
import s from "./Content.module.scss";
import useGetImage from "../../useGetImage";
const HopProducts = () => {
    const [open, setOpen] = useState(false);
    const { hopExpress } = useGetImage();
    const nodes = [
        {
            id: "1",
            name: "HopExpress™",
            url: "",
        },
        {
            id: "2",
            name: "HopTechnics™ Selection Tool",
            url: "https://hoptechnic.com/app",
        },
    ];
    const actionHandle = id => {
        if (id === "2") {
            window.open("https://hoptechnic.com/app", "_blank");
        }
        else {
            setOpen(true);
        }
    };
    return (<section className={s.box}>
      <div className={s.title2}>
        <h1>Hop Products</h1>
      </div>
      <div className={s.item2}>
        {nodes.map(function (element) {
        return (<div className={s.textBu} key={element.name}>
              <button onClick={() => actionHandle(element.id)}>
                <h5>{element.name}</h5>
              </button>
            </div>);
    })}
      </div>
      <Popup open={open} modal closeOnDocumentClick onClose={() => setOpen(false)} lockScroll>
        <img src={hopExpress.publicURL} alt="Hop Express"/>
      </Popup>
    </section>);
};
export default HopProducts;
