import { graphql, useStaticQuery } from "gatsby";
export default () => {
    const { tableData: { nodes }, } = useStaticQuery(graphql `
  query {
    tableData: allContentfulStandartHopVarieties(sort: {fields: name}) {
      nodes {
        alphaAcidRange
        aromaProfile
        betaAcidRange
        contentful_id
        growingRegion
        name
        origin
        url
        totalOilRange
        flag {
          fluid {
            src
          }
        }
      }
    }
  }
`);
    return { nodes };
};
