import React from "react";
import getTableDataOrganic from "../../../../components/table/getTableDataOrganic";
import s from "./Content.module.scss";
const Content3 = () => {
    const { nodes } = getTableDataOrganic();
    return (<section className={s.box}>
           
    <div className={s.title2}>
                <h1>Organic Varieties </h1>
            </div>
        <div className={s.item2}>       
        
        {nodes.map(function (element) {
        return <div className={s.textBu} key={element.name}>
    <button onClick={() => window.location.assign(`/hop-varieties/${element.url}`)}>
        <h5>{element.name}</h5>
        <p className={s.textCap}>{element.aromaProfile}</p>
      </button>
  </div>;
    })}
    </div> 




    </section>);
};
export default Content3;
