import { useStaticQuery, graphql } from "gatsby";
export default () => {
    const data = useStaticQuery(graphql `
    query {
      firstImage: file(
        relativePath: { eq: "images/hop_varieties/HopVarieties-1.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
    return data;
};
