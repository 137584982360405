import React from "react";
import Hero from "../../components/hero";
import SectionSeparator from "../../components/sectionSeparator";
import useGetImage from "./useGetImage";
import Content from "./components/content";
import s from "./HopVarieties.module.scss";
import Content2 from "./components/content/Content2";
import Content3 from "./components/content/Content3";
import Content4 from "./components/content/Content4";
import HopProducts from "./components/content/HopProducts";
const HopVarieties = (props) => {
    const { hero } = useGetImage();
    return (<>
      <Hero imgURL={hero.childImageSharp.fluid}/>
      <SectionSeparator />
      <div className={s.wrap}>
        <Content />
        <HopProducts />
        <Content2 />
        <Content3 />
        <Content4 />
      </div>
    </>);
};
export default HopVarieties;
