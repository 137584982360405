import React from "react";
import HopVarieties from "../scenes/hopVarieties";
import SEO from "../components/seo";
import { keywords } from "../content/keywords";
export default () => {
    return (<>
      <SEO title={"Hop Varieties"} keywords={keywords}/>
      <HopVarieties />
    </>);
};
